export const SideNavData = (showEmail) => [
  // {
  //   path: "/",
  //   label: "الرئيسية",
  //   icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1698247052/home_in8i1t.png",
  // },
  {
    path: "/Message_History",
    label: "الملف الشخصي",
    label_en: "Profile",
    icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1698247125/user_mevq6q.png",
  },
  showEmail
    ? {
        path: "/invoice",
        label: "فواتيرك",
        label_en: "Invoice",
        icon: "https://res.cloudinary.com/dbz6ebekj/image/upload/v1728469223/bill_wbhbcq.png",
      }
    : {},
  {
    path: "/tahweelat",
    label: "التحويلات",
    label_en: "Transfers",
    icon: "https://res.cloudinary.com/dbz6ebekj/image/upload/v1728469223/bill_wbhbcq.png",
  },
  // {
  //   path: "/website_data",
  //   label: " الأشخاص",
  //   icon: "https://res.cloudinary.com/duovxefh6/image/upload/v1698250740/doctor-consultation_d6xmry.png",
  // },
];
