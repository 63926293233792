import React, { useEffect, useState } from 'react';
import AppContext from './appContext';
import axios from 'axios';

const AppProvider = ({ children }) => {
  const [appSettings, setSettings] = useState(null);
  const getSettings = () => {
    axios
      .get("https://baseeta-form.com/basita/get_settings.php")
      .then((res) => {
        setSettings(res?.data?.message);
      });
  };
  useEffect(() => {
    getSettings();
  }, []);
 
  return (
    <AppContext.Provider value={{ appSettings, setSettings }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;